import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

class TestimonialsPage extends React.Component {
  render() {
    const { data } = this.props

    if (!data || !data.testimonials) return null;

    const { edges: testimonials } = data.testimonials

    const testimonialColumns = [{ list: [], num: 0 }, { list: [], num: 0 }, { list: [], num: 0 }]

    testimonials.forEach(element => {
      if (testimonialColumns[0].num <= testimonialColumns[1].num && testimonialColumns[0].num <= testimonialColumns[2].num) {
        testimonialColumns[0].list.push(element.node)
        testimonialColumns[0].num += element.node.content.length
      } else if (testimonialColumns[1].num <= testimonialColumns[2].num) {
        testimonialColumns[1].list.push(element.node)
        testimonialColumns[1].num += element.node.content.length
      } else {
        testimonialColumns[2].list.push(element.node)
        testimonialColumns[2].num += element.node.content.length
      }
    });

    return (
      <Layout>
        <Helmet title="Testimonials | Fluxible" />
        <section className="section grid-container pre-footer-padding">
          <h1 className="text-center page-header">What past speakers and attendees have to say about Fluxible</h1>
          {/*<div className="grid-x grid-margin-x">
            {testimonials.map(({ node: blurb }) => (
              <div className="cell small-4 testimonial-item" key={blurb.id}>
                <blockquote>{blurb.content}</blockquote>
              </div>
            ))}
            </div>*/}
          <div className="testimonial-container">
            {testimonialColumns.map((col, idx) => (
              <div className="testimonial-column" key={idx}>
                {col.list.map((blurb) => (
                  <div className="testimonial-item" key={blurb.id}>
                    <blockquote>{blurb.content}</blockquote>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </section>
      </Layout>
    )
  }
}

TestimonialsPage.propTypes = {
  data: PropTypes.shape({
    allFluxibleTestimonials: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default TestimonialsPage

export const testimonialsQuery = graphql`
  query TestimonialsPageQuery {
    testimonials: allFluxibleTestimonials(
        filter: { content: { ne: "" } }
      ) {
      edges {
        node {
            id
            content
        }
      }
    }
  }
`
